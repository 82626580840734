import Typography from 'typography'
import 'typeface-quattrocento-sans'
import presets, { colors } from './presets'

import {
  MOBILE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
  MIN_DEFAULT_MEDIA_QUERY,
  MIN_LARGER_DISPLAY_MEDIA_QUERY,
} from 'typography-breakpoint-constants'

const options = {
  headerFontFamily: [
    `Quattrocento Sans`,
    `Helvetica Neue`,
    `Arial`,
    `sans-serif`,
  ],
  bodyFontFamily: [
    `Quattrocento Sans`,
    `Helvetica Neue`,
    `Arial`,
    `sans-serif`,
  ],
  headerColor: colors.ui.dark,
  baseFontSize: `16px`,
  baseLineHeight: 1.5,
  bodyColor: colors.ui.copy,
  blockMarginBottom: 0.75,
  overrideStyles: ({ rhythm, scale }, options) => {
    return {
      // add any specific overrides here that are outside
      // typography.js options here -
      // https://kyleamathews.github.io/typography.js/
      a: {
        color: `inherit`,
        textDecoration: `none`,
        transition: `all ${presets.animation.speedFast} ${
          presets.animation.curveDefault
          }`,
        borderBottom: `1px solid ${colors.ui.light}`,
        boxShadow: `inset 0 -2px 0px 0px ${colors.ui.light}`,
        fontWeight: `bold`,
      },
      blockquote: {
        backgroundColor: colors.ui.light,
        borderBottom: `4px solid ${colors.ui.light}`,
        borderTop: `4px solid ${colors.ui.light}`,
        fontSize: `150%`,
        padding: rhythm(options.blockMarginBottom * 2),
        marginLeft: `0`,
        marginRight: `0`,
        marginBottom: rhythm(options.blockMarginBottom),
      },
      'a:hover': {
        backgroundColor: colors.ui.light,
      },
      'a.gatsby-resp-image-link': {
        boxShadow: `none`,
        borderBottom: `transparent`,
      },
      'a.gatsby-resp-image-link:hover': {
        background: `none`,
        boxShadow: `none`,
      },
      '.link-style': {
        borderBottom: `none`,
        boxShadow: `none`,
        fontWeight: `inherit`,
      },
      '.link-style:hover': {
        background: `none`,
        boxShadow: `none`,
        color: colors.ui.calm,
      },
      '.link-style:after': {
        content: `→`,
        paddingLeft: rhythm(1 / 4),
      },
      'tt,code,kbd': {
        // background: `hsla(23, 60%, 97%, 1)`,
        fontSize: `80%`,
        // Disable ligatures as they look funny w/ Space Mono as code.
        fontVariant: `none`,
        WebkitFontFeatureSettings: `"clig" 0, "calt" 0`,
        fontFeatureSettings: `"clig" 0, "calt" 0`,
        paddingTop: `0.1em`,
        paddingBottom: `0.1em`,
      },
      '.gatsby-highlight': {
        backgroundColor: `#F7F6F5`,
        borderRadius: `${presets.radius}px`,
        padding: rhythm(options.blockMarginBottom),
        marginBottom: rhythm(options.blockMarginBottom),
        overflow: `auto`,
        WebkitOverflowScrolling: `touch`,
        position: `relative`,
      },
      ".gatsby-highlight pre[class*='language-']": {
        padding: 0,
        marginTop: 0,
        marginBottom: 0,
        backgroundColor: `transparent`,
        border: 0,
        float: `left`,
        minWidth: `100%`,
        overflow: `initial`,
      },
      '.gatsby-highlight pre code': {
        display: `block`,
        fontSize: `95%`,
        lineHeight: options.baseLineHeight,
      },
      '.gatsby-highlight-code-line': {
        background: `#E4E3E2`,
        marginRight: `${rhythm(-options.blockMarginBottom)}`,
        marginLeft: `${rhythm(-options.blockMarginBottom)}`,
        paddingRight: rhythm(options.blockMarginBottom),
        paddingLeft: `${rhythm(options.blockMarginBottom / 5 * 4)}`,
        borderLeft: `${rhythm(options.blockMarginBottom / 5 * 1)} solid ${
          colors.accent
          }`,
        display: `block`,
      },
      '.gatsby-highlight::-webkit-scrollbar': {
        width: `6px`,
        height: `6px`,
      },
      '.gatsby-highlight::-webkit-scrollbar-thumb': {
        background: `#C2C2C2`,
        borderRadius: `${presets.radius}px`,
      },
      '.gatsby-highlight::-webkit-scrollbar-track': {
        background: `#FBFBFB`,
      },
      [MIN_DEFAULT_MEDIA_QUERY]: {
        // Add in some media query stuff here -
        html: {
          fontSize: `20px`,
        },
      },
      '@media (max-width:634px)': {
        '.gatsby-highlight': {
          marginLeft: rhythm(-options.blockMarginBottom * 1.5),
          marginRight: rhythm(-options.blockMarginBottom * 1.5),
          padding: rhythm(options.blockMarginBottom * 1.5),
          marginBottom: rhythm(options.blockMarginBottom * 1.5),
        },
        '.gatsby-highlight-code-line': {
          marginRight: `${rhythm(-options.blockMarginBottom * 1.5)}`,
          marginLeft: `${rhythm(-options.blockMarginBottom * 1.5)}`,
          paddingRight: rhythm(options.blockMarginBottom * 1.5),
          paddingLeft: `${rhythm(options.blockMarginBottom * 1.5 / 5 * 4)}`,
          borderLeftWidth: `${rhythm(options.blockMarginBottom * 1.5 / 5 * 1)}`,
        },
      },
    }
  },
}

const typography = new Typography(options)

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export const { scale, rhythm } = typography
export default typography

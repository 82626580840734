import gray from 'gray-percentage'
// use as a reference
// https://github.com/gatsbyjs/gatsby/blob/master/www/src/utils/colors.js

const colors = {
  accent: gray(13),
  ui: {
    dark: gray(13),
    copy: gray(13, 204),
    calm: gray(46, 204),
    light: gray(88, 204),
  },
}

export default colors
